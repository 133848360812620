import React from 'react';
import { Container } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import bugsIntegrationtImage from '../assets/images/bugswork-integration.gif';
import bugsInviteImage from '../assets/images/bugswork-invite.png';
import addScript from '../assets/images/bugs-wrok-addScript.gif';
import addingTask from '../assets/images/bugs-work-addingTask.gif';

function FeatureOverview() {
  const cardData = [
    {
      cardSubtitle: 'Easy to use',
      cardTitle:
        'Seamlessly integrate by simply including the script in your project',
      cardImage: addScript,
    },
    {
      cardSubtitle: 'One Click Bug Reporting',
      cardTitle:
        'Use the power of Markdown to write, assign, and activate tasks all in one streamlined process, and create them instantly',
      cardImage: addingTask,
    },
    {
      cardSubtitle: 'Simple integrations',
      cardTitle:
        'Integrate effortlessly with your existing favorite task management tools.',
      cardImage: bugsIntegrationtImage,
    },
    {
      cardSubtitle: 'Adding project members',
      cardTitle: 'Together, we can do so much more—start collaborating today!',
      cardImage: bugsInviteImage,
    },
  ];

  return (
    <div className="feature-section py-4 py-lg-5">
      <Container>
        <div className="d-flex flex-column justify-content-between text-center h-100 ">
          <div className="h-50 d-flex flex-column justify-content-end">
            <div className="text-white d-flex flex-column justify-content-center align-items-center">
              <div className="fw-600 heading-hero-section">
                Everyone's tool,
              </div>
              <div className="fw-600 heading-hero-section">
                developer's favorite.
              </div>
            </div>
            <div className="d-flex flex-wrap justify-content-center">
              {cardData.map((card, index) => (
                <Card
                  key={index}
                  className="text-white m-2 w-40 w-sm-100 feature-overview-card"
                >
                  <Card.Body>
                    <Card.Subtitle className="mb-3 text-uppercase text-align-justify feature-overview-card-subtitle">
                      {card.cardSubtitle}
                    </Card.Subtitle>
                    <Card.Title className="mb-4 text-align feature-overview-card-title">
                      {card.cardTitle}
                    </Card.Title>
                    <div className="px-3 py-3">
                      <img
                        src={card.cardImage}
                        alt="arrow"
                        className="w-100 h-auto"
                      />
                    </div>
                  </Card.Body>
                </Card>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default FeatureOverview;
