import React from 'react';
import { Button, Card, Container } from 'react-bootstrap';
import calender from '../assets/images/calender-icon.svg';
import bugCommentImage from '../assets/images/bugs-comments.png';
import bugConsoleImage from '../assets/images/bugs-console.png';
import bugSystemInfoImage from '../assets/images/bugs-systeminfo.png';
import bugSyNetworkImage from '../assets/images/bugs-network.png';
function TaskView() {
  const buttonList = [
    {
      id: 1,
      title: 'System Info',
      icon: calender,
      imageSrc: bugSystemInfoImage,
    },
    {
      id: 2,
      title: 'Console',
      icon: calender,
      imageSrc: bugConsoleImage,
    },
    {
      id: 3,
      title: 'Network',
      icon: calender,
      imageSrc: bugSyNetworkImage,
    },
    {
      id: 4,
      title: 'Comments',
      icon: calender,
      imageSrc: bugCommentImage,
    },
  ];

  const [selectedImage, setSelectedImage] =
    React.useState<string>(bugSystemInfoImage);

  function onSelectedImage(imageSrc: string): void {
    setSelectedImage(imageSrc);
  }

  return (
    <div className=" py-4 py-lg-5">
      <Container>
        <div className="d-flex flex-column justify-content-between text-center h-100 ">
          <div className="h-50 d-flex flex-column justify-content-start">
            <div className="base-color d-flex flex-column justify-content-center align-items-center">
              <div className="fw-600 heading-hero-section">
                Say Goodbye to Explaining Bugs
              </div>
              <div className="fw-400 mt-2">
                Auto-captures all the info engineers need to debug, including:
              </div>
            </div>
            <div className="d-flex gap-sm-5 gap-3 justify-content-start mt-4 ms-2 flex-wrap">
              {buttonList.map((button, index) => (
                <Button
                  key={button.id}
                  size="lg"
                  className="py-2 font-14"
                  variant="outline-success"
                  onClick={() => onSelectedImage(button.imageSrc)}
                  active={button.imageSrc === selectedImage}
                >
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="book-a-demo">{button.title}</div>
                  </div>
                </Button>
              ))}
            </div>
            <div className="mt-4">
              <Card className="text-white m-2">
                <Card.Body>
                  <img src={selectedImage} alt="arrow" className="ms-2 w-100" />
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default TaskView;
